
import { defineComponent } from "vue"
import store from "@/store/store"
import { isVotingPossible } from "@/helpers/feedback"
import { DBError } from "@/helpers/types"

export default defineComponent({
    name: "FeedbackVote",
    store,

    props: {
        feedbackId: { type: Number, required: true },
        feedbackReplyId: { type: Number, default: -1 },
    },

    computed: {
        isVotingPossible: (): boolean => {
            return isVotingPossible
        },

        voteValue: {
            get (): number {
                return store.getters.getFeedbackVote(this.feedbackId, this.feedbackReplyId)
            },
            set (voteValue: number) {
                store.commit("setFeedbackVoteUp", {
                    feedbackId: this.feedbackId,
                    feedbackReplyId: this.feedbackReplyId,
                    value: voteValue,
                })
            },
        },

        isVotedUp: {
            get (): boolean {
                return store.getters.getIsFeedbackVotedUp(this.feedbackId, this.feedbackReplyId)
            },
            set () {
                // Increment the vote
                this.voteValue = (this.voteValue + 1)
            },
        },

        dbErrorInfos (): DBError {
            return store.getters.getDBError()
        },
    },

    watch: {
        // If the vote couldn't be sent to the DB we need to let user know (because it won't update on the UI too)
        dbErrorInfos (currErrorInfo, oldErrorInfo) {
            if (!oldErrorInfo.flag && currErrorInfo.flag && currErrorInfo.payload &&
                currErrorInfo.payload[0] === this.feedbackId && currErrorInfo.payload[1] === this.feedbackReplyId) {
                alert("Your like could not be sent to us:\n" + (this.dbErrorInfos.error ?? "an error occured"))
            }
        },
    },

    methods: {
        doVoteUp () {
            this.isVotedUp = true
        },
    },
})

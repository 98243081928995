
import { defineComponent } from "vue"
import FeedbackFilter from "@/components/FeedbackFilter.vue"
import FeedbackSummary from "@/components/FeedbackSummary.vue"
import Progress from "@/components/Progress.vue"
import Error from "@/components/Error.vue"
import { DBConsts, DBError, DBFeedbackQueryResponse, DBOperationType, FeedbackFilterData, FeedbackItem } from "@/helpers/types"
import store from "@/store/store"
import { newFeedbackRoutePath } from "@/router/router"
import axios from "axios"

export default defineComponent({
    name: "FeedbackSummaryControlsView",

    components: {
        FeedbackFilter,
        FeedbackSummary,
        Progress,
        Error,
    },

    data: function () {
        return {
            showFeedbackFilter: false,
            hasFilter: false,
            isSearchEnabled: false,
            hasSearch: false,
            feedbackFilterData: { } as FeedbackFilterData,
            noFeedbackMsg: "No feedback reported yet",
        }
    },

    computed: {
        currentDBOperationType (): DBOperationType {
            return store.getters.getDBOperation().type
        },

        isDBLoadingFeedbacks (): boolean {
            return this.currentDBOperationType === DBOperationType.Feedbacks
        },

        isDBLoadingFilteredFeedbacks (): boolean {
            return this.currentDBOperationType === DBOperationType.FilteredFeedbacks
        },

        isDBLoadingMoreFeedbacks (): boolean {
            return this.currentDBOperationType === DBOperationType.MoreFeedbacks || this.currentDBOperationType === DBOperationType.MoreFilteredFeedbacks
        },

        feedbackItems (): FeedbackItem[] {
            return store.getters.getFeedbackItems()
        },

        newFeedbackRoute (): string {
            return newFeedbackRoutePath
        },

        showLoadMoreLink (): boolean {
            return this.feedbackItems.length < store.getters.getFeedbackTotalNber()
        },

        dbErrorInfos (): DBError {
            return store.getters.getDBError()
        },

        hasDBFeedbacksError (): boolean {
            return this.dbErrorInfos.flag && this.dbErrorInfos.operation === DBOperationType.Feedbacks
        },

        hasDBFilteredFeedbacksError (): boolean {
            return this.dbErrorInfos.flag && this.dbErrorInfos.operation === DBOperationType.FilteredFeedbacks
        },

        hasDBMoreFeedbacksError (): boolean {
            return this.dbErrorInfos.flag && (this.dbErrorInfos.operation === DBOperationType.MoreFeedbacks || this.dbErrorInfos.operation === DBOperationType.MoreFilteredFeedbacks)
        },

        dbErrorMsg (): string {
            return this.dbErrorInfos.error?.toString() ?? ""
        },
    },

    watch: {
        // whenever we notify a change in the DB operation, we keep the message for no result to date
        currentDBOperationType (newType, oldType) {
            if (newType === DBOperationType.FilteredFeedbacks.valueOf()) {
                this.noFeedbackMsg = "No feedback match the search / filter"
            }
            else if (newType === DBOperationType.Feedbacks.valueOf()) {
                this.noFeedbackMsg = "No feedback reported yet"
            }
        },
    },

    methods: {
        checkIsSearchEnabled (event: InputEvent) {
            this.isSearchEnabled = (event.target as HTMLInputElement).value.trim().length > 0
            if (!this.isSearchEnabled && this.hasSearch) {
                // when the search box has been cleared, we make sure that we revert to a state where the user can actually get rid of the search
                // if a search had been made
                this.clearSearchFeedback(true)
            }
        },

        searchFeedback () {
            // Send a search request to the server (there will always be something as button is only clickable when a search string is present)
            if (this.isSearchEnabled) {
                this.hasSearch = true
                this.feedbackFilterData.search = (document.getElementById("searchInput") as HTMLInputElement).value.trim()
                store.commit("setFeedbacks", this.feedbackFilterData)
            }
        },

        clearSearchFeedback (force?: boolean) {
            // When we clear the search box (making sure there is content to clear) we also need to refresh the current list of feedbacks
            if (force || this.isSearchEnabled) {
                (document.getElementById("searchInput") as HTMLInputElement).value = ""
                this.feedbackFilterData.search = undefined
                this.isSearchEnabled = false
                this.hasSearch = false
                store.commit("setFeedbacks", this.feedbackFilterData)
            }
        },

        toggleFilterFeedback () {
            // toggle the filter feedback form
            this.showFeedbackFilter = !this.showFeedbackFilter
        },

        filterActionPerformed (hasContent: boolean) {
            this.hasFilter = hasContent
            const filterData = { ...(this.$refs.feedbackFilter as typeof FeedbackFilter).getFilterData() as FeedbackFilterData }
            filterData.search = this.feedbackFilterData.search
            this.feedbackFilterData = filterData
            this.showFeedbackFilter = false
            // update the data
            store.commit("setFeedbacks", this.feedbackFilterData)
        },

        /* updateFilterDateIsOn () {
            this.feedbackFilterData.isOn = ((this.feedbackFilterData.search?.length ?? 0) > 0 || (this.feedbackFilterData.status?.length ?? 0) > 0 ||
            (this.feedbackFilterData.tags?.length ?? 0) > 0 || (this.feedbackFilterData.dateTimeFrom?.length ?? 0) > 0 ||
                (this.feedbackFilterData.dateTimeTo?.length ?? 0) > 0 || (this.feedbackFilterData.author?.length ?? 0) > 0)
        }, */

        loadMoreFeedbacks () {
            // Depending if filter/search is activated, we load more filtered feedbacks or all feedback
            const hasFilteredFeedback = this.hasFilter || this.hasSearch
            // Get the next available feedback from the DB and appened it to the current state feedback
            const currentLastFeedbackItemId = this.feedbackItems[this.feedbackItems.length - 1].id
            // We make a query -- we set the flag that a query is in process for the UI to be aware of a pending process
            // and clear the error flag
            store.commit("setDBOperation", { type: (hasFilteredFeedback) ? DBOperationType.MoreFilteredFeedbacks : DBOperationType.MoreFeedbacks })
            store.commit("setDBError", { flag: false })
            const dbConsts = (store.getters.getDBConsts() as DBConsts)
            axios.post(dbConsts.serverhost + ((hasFilteredFeedback) ? "search" : ""), { limit: dbConsts.itemsPerLoad, startFromId: currentLastFeedbackItemId, ...this.feedbackFilterData })
                .then((response) => {
                    // notes: 1) the comments are not retrieved from the DB with the feedbacks, so we make sure the property is set with a value ([])
                    //        2) the front end data model expects tags in an array --> we parse what we got in the DB
                    const dbResponse = response.data as DBFeedbackQueryResponse
                    const feedbackTotalNumber = dbResponse.total_entries[0].number
                    const feedbackItems = dbResponse.feedback
                    for (const feedback of feedbackItems) {
                        feedback.comments = []
                        feedback.tags = ((feedback.tags as unknown) as string).split(",")
                    }
                    store.commit("setFeedbackTotalNber", feedbackTotalNumber)
                    store.commit("setMoreFeedbacks", feedbackItems)
                })
                .catch((error) => store.commit("setDBError", { flag: true, error: error, operation: (hasFilteredFeedback) ? DBOperationType.MoreFilteredFeedbacks : DBOperationType.MoreFeedbacks }))
                .finally(() => store.commit("setDBOperation", { type: DBOperationType.None }))
        },
    },
})


import { StrypeHistoryEntry } from "@/helpers/types"
import { defineComponent } from "vue"

export default defineComponent({
    name: "StrypeHistoryView",

    computed: {
        history (): StrypeHistoryEntry[] {
            // Rather than getting the content directly as HTML entries, we store them here as JS objects and get a simple rendereding in the HTML template
            // note: the string content can include HTML tags if bold, italic etc is needed within.
            return [
                {
                    date: "12 May 2022",
                    generalDetails: ["Added a specific frame for the <code>global</code> keyword.",
                        "Transformation of function call frames which contain a variable assignment to a variable assignment frame (when frame is edited).",
                        "Bug fixes (state backup, reactivity, disabled frames(navigation, parsing and error management), allow the option key to be used for text fast-selection on Mac)."],
                    standardEditorDetails: ["Show runtime errors when using the Python console.",
                        "Added a button to toggle the Python console's display size.",
                        "Fixed Python console rendering on Mac, enlarge font for all."],
                    microbitEditorDetails: ["Fixed a mistake in the API explorer (<code>Image.</code> instead of <code>Img.</code>)."],
                },
                {
                    date: "07 April 2022",
                    generalDetails: ["Allow to delete a function definition frame from within when the frame has no children.",
                        "Improvements</b> in Strype project’s autosave : timed autosave, smaller data saved on the  browser local storage.",
                        "Localisation of add frame commands.",
                        "From this version, saved projects will only be able to be loaded in Strype if the editor’s “platform” is the same (standard editor, or micro:bit), older saved project can still be loaded.",
                        "Added a “reset” entry in the menu to reset the editor’s to the default Strype project.",
                        "Bug fixes (cut context menu, ghost key event listeners leading to frames structure inconsistency, check errors when enabling a frame, removed the micro:bit API documentation from the spy/saved projects, fixed double entries with localised autocompletion for “my variables” and “my functions”)."],
                    standardEditorDetails: ["Python console: support for in-console input, better integration with the page (styling), fixed problems with scrolling and typing changing the editor."],
                    microbitEditorDetails: ["Replaced the default Strype project content with micro:bit related code."],
                },
                { date: "04 March 2022", preambleDetails: "Release of both the Strype standard editor and Strype for micro:bit." },
            ]
        },
    },
})

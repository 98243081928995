
import { defineComponent } from "vue"

export default defineComponent({
    name: "Progress",

    props: {
        label: { type: String, default: "" },
        size: { type: String, default: "3em" },
    },

    computed: {
        style () : Record<string, string> {
            return { width: this.size, height: this.size }
        },
    },
})


import { defineComponent } from "vue"

export default defineComponent({
    name: "Error",

    props: {
        label: { type: String, default: "" },
        size: { type: String, default: "3em" },
    },

    computed: {
        style () : Record<string, string> {
            return { "font-size": this.size, color: "#689e52" }
        },
    },
})

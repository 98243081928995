
import { defineComponent } from "vue"
import store from "@/store/store"
import NewFeedback from "@/components/NewFeedback.vue"
import { beforeUnloadEvent } from "@/helpers/misc"
import { feedbacksRoutePath } from "@/router/router"
import { DBOperationType } from "@/helpers/types"

export default defineComponent({
    name: "NewFeedbackView",
    store,

    components: {
        NewFeedback,
    },

    computed: {
        feedbackRoute (): string {
            return feedbacksRoutePath
        },

        isDBSendingFeedback (): boolean {
            return store.getters.getDBOperation().type === DBOperationType.NewFeedback
        },
    },

    created () {
        window.addEventListener("beforeunload", beforeUnloadEvent)
    },
})

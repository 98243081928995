
import { defineComponent } from "vue"
import { FeedbackFilterData, FeedbackStatus, FeedbackStatusDetail } from "@/helpers/types"
import { getStatusClassIcon, statusDetails } from "@/helpers/feedback"
import { getLocale } from "@/helpers/misc"
import FeedbackTags from "@/components/FeedbackTags.vue"
import Datepicker from "vue3-date-time-picker"
import "vue3-date-time-picker/dist/main.css"
import store from "@/store/store"
import { getFormattedUTCStrDateFromLocal } from "@/helpers/dateRendering"

export default defineComponent({
    name: "FeedbackFilter",
    store,

    components: {
        FeedbackTags,
        Datepicker,
    },

    emits: ["filter-reset", "filter-applied"],

    data: function () {
        return {
            // both dates are actually typed to Date from Datepicker
            dateFrom: null,
            dateTo: null,
            hasContent: false,
            hasDateError: false,
            filterData: {} as FeedbackFilterData,
        }
    },

    computed: {
        authorInputId (): string {
            return "feedbackFilterAuthorInput"
        },

        dateFromInputId (): string {
            return "filterDateFromInput"
        },

        dateToInputId (): string {
            return "filterDateToInput"
        },

        statusDetailedList (): FeedbackStatusDetail[] {
            return statusDetails
        },

        getLocale (): string {
            return getLocale()
        },
    },

    methods: {
        getStatusClass (status: FeedbackStatus): string {
            return "fa-fw " + getStatusClassIcon(status)
        },

        setPendingChanges () {
            // Updates the flag indicating if a filter has content (when a change occured)
            let hasChanges = false // init

            // check the status checkboxes
            const statusCheckBoxes = document.getElementsByClassName("feedback-status-checkbox")
            for (const checkbox of statusCheckBoxes) {
                hasChanges = hasChanges || (checkbox as HTMLInputElement).checked
            }

            // check the tags
            hasChanges = hasChanges || (((this.$refs.tagsComponent as typeof FeedbackTags).getTags().length) > 0)

            // check the dates
            hasChanges = hasChanges || this.dateFrom != null || this.dateTo != null

            // check all other input text fields (author)
            const filterInputs = document.getElementsByClassName("filter-input")
            for (const input of filterInputs) {
                hasChanges = hasChanges || ((input as HTMLInputElement).value.trim().length > 0)
            }

            this.hasContent = hasChanges
        },

        applyFilter () {
            // We check that there is no discrepancy in the filter parameters (e.g. dates order)
            // and format the fields if needed (e.g. the date times)

            // Dates are not in order --> show error and stop the validation
            this.hasDateError = (this.dateFrom != null && this.dateTo != null && ((this.dateTo as Date) < (this.dateFrom as Date)))

            // If everything is fine, we can then send an event the filter has been applied
            // Note: if a search query is also performed, the filter is made on the search results
            if (!this.hasDateError) {
                // We send the post query to the backend DB - only set then fields that we need to check against.
                this.filterData = { }
                // status
                const statusContainer = document.getElementById("feedbackStatusListDiv")
                if (statusContainer != null) {
                    const statusArray = [] as number[]
                    Array.from(statusContainer.children).forEach((child) => {
                        const checkInputNode = child.children.item(0) as HTMLInputElement
                        if (checkInputNode.checked) {
                            statusArray.push(parseInt(checkInputNode.value))
                        }
                    })
                    if (statusArray.length > 0) {
                        this.filterData.status = statusArray
                    }
                }
                // Tags
                const tags = (this.$refs.tagsComponent as typeof FeedbackTags).getTags() as string[]
                if (tags.length > 0) {
                    this.filterData.tags = [...tags]
                }
                // Date
                // We work out how to pass the date values for the time parts: 00:00:00 for "from" and 23:59:59 for "to"
                // *of the browser detected time zone* (as DB is in UTC, we convert to UTC)
                if (this.dateFrom !== null) {
                    this.filterData.dateTimeFrom = getFormattedUTCStrDateFromLocal(this.dateFrom, true)
                }
                if (this.dateTo !== null) {
                    this.filterData.dateTimeTo = getFormattedUTCStrDateFromLocal(this.dateTo, false, true)
                }
                // Author
                if (((document.getElementById(this.authorInputId) as HTMLInputElement)?.value.trim().length ?? 0) > 0) {
                    this.filterData.author = (document.getElementById(this.authorInputId) as HTMLInputElement)?.value.trim()
                }

                this.$emit("filter-applied")
            }
        },

        resetFilter () {
            // Clear everything from the filter
            // status checkboxes
            const statusCheckBoxes = document.getElementsByClassName("feedback-status-checkbox")
            for (const checkbox of statusCheckBoxes) {
                (checkbox as HTMLInputElement).checked = false
            }

            // clear the tags
            (this.$refs.tagsComponent as typeof FeedbackTags).clearTags()

            // clear the dates
            this.dateFrom = null
            this.dateTo = null

            // check all other input text fields (author)
            const filterInputs = document.getElementsByClassName("filter-input")
            for (const input of filterInputs) {
                (input as HTMLInputElement).value = ""
            }

            this.hasContent = false
            this.filterData = { }

            // Send an event the filter has been cleared
            this.$emit("filter-reset")
        },

        getFilterData () {
            return this.filterData
        },
    },
})


import { defineComponent } from "vue"
import store from "@/store/store"

export default defineComponent({
    name: "HomeView",
    store,
    computed: {
        isScreenPortrait (): boolean {
            return store.getters.getIsScreenPortrait()
        },
    },
})

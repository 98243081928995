
import { defineComponent } from "vue"
import { feedbacksRoutePath, newFeedbackRoutePath, strypeHistoryRoutePath } from "./router/router"
import store from "./store/store"

export default defineComponent({
    name: "App",
    store,

    computed: {
        displayedYear (): number {
            const currentDate = new Date()
            return currentDate.getFullYear()
        },

        feedbackRoute (): string {
            return feedbacksRoutePath
        },

        strypeHistoryRoute (): string {
            return strypeHistoryRoutePath
        },

        isScreenPortrait (): boolean {
            return store.getters.getIsScreenPortrait()
        },
    },

    methods: {
        isFeedbackRelatedRoute (): boolean {
            // This method returns true if we are on the feedback route or the new feedback route
            return this.$route.path.startsWith(this.feedbackRoute) || this.$route.path.startsWith(newFeedbackRoutePath)
        },
    },
})

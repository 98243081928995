
import store from "@/store/store"
import { defineComponent } from "vue"
import FeedbackTags from "@/components/FeedbackTags.vue"
import Progress from "@/components/Progress.vue"
import Error from "@/components/Error.vue"
import { VueRecaptcha } from "vue-recaptcha"
import { getLineBreaksParsedFreeText, pasteTextOnly } from "@/helpers/misc"
import { DBConsts, DBError, DBOperationType } from "@/helpers/types"
import { feedbacksRoutePath } from "@/router/router"

export default defineComponent({
    name: "NewFeedback",
    store,

    components: {
        FeedbackTags,
        VueRecaptcha,
        Progress,
        Error,
    },

    data () {
        return {
            hasPendingChanges: false,
            isUserHuman: false,
            isTitleEmpty: false,
            isDescriptionEmpty: false,
            isDescriptionTooBig: false,
        }
    },

    computed: {
        titleMaxLength () : number {
            return (store.getters.getDBConsts() as DBConsts).shortTextMax
        },

        authorMaxLength () : number {
            return (store.getters.getDBConsts() as DBConsts).extraShortTextMax
        },

        descMaxLength (): number {
            return (store.getters.getDBConsts() as DBConsts).longTextMax
        },

        captchaKey (): string {
            return store.getters.getCaptchaKey()
        },

        titleInputId (): string {
            return "newFeedbackTitle"
        },

        descriptionDivId (): string {
            return "newFeedbackDescriptionDiv"
        },

        authorInputId (): string {
            return "newFeedbackAuthorInput"
        },

        isDBSendingFeedback (): boolean {
            return store.getters.getDBOperation().type === DBOperationType.NewFeedback
        },

        dbErrorInfos (): DBError {
            return store.getters.getDBError()
        },

        hasDBError (): boolean {
            return this.dbErrorInfos.flag && this.dbErrorInfos.operation === DBOperationType.NewFeedback
        },

        dbErrorMsg (): string {
            return this.dbErrorInfos.error?.toString() ?? ""
        },
    },

    watch: {
        // whenever we notify sending has finished, if there are no error we can move on
        isDBSendingFeedback (isSending, wasSending) {
            if (wasSending && !isSending && !this.hasDBError) {
                // we reset the pending changes
                store.commit("setHasPendingChanges", false)
                // return to the feedback list - BUT we don't update the feedback from DB in that case
                // to see the new feedback on the top
                store.commit("setFetchFeedbackFromDB", false)
                this.$router.push(feedbacksRoutePath)
            }
        },
    },

    methods: {
        setPendingChanges (sourceId?: string) {
            let hasPendingChanges = false
            const htmlFields = document.getElementsByClassName("feedback-changeable")
            for (const input of htmlFields) {
                let isInputEmpty
                if (input instanceof HTMLInputElement) {
                    // this applies to INPUT
                    isInputEmpty = (((input as HTMLInputElement).value.trim().length) === 0)
                }
                else {
                    // this applies to DIV or SPAN
                    isInputEmpty = ((((input as HTMLDivElement).textContent?.trim().length) ?? 0) === 0)
                }
                hasPendingChanges = hasPendingChanges || !isInputEmpty
            }

            // For the tags, we can directly check the data property
            hasPendingChanges = hasPendingChanges || (((this.$refs.tagsComponent as typeof FeedbackTags).getTags().length) > 0)

            // We set the changes flag to "true" in the store if any of the feedback form fields has a non empty value
            store.commit("setHasPendingChanges", hasPendingChanges)

            this.hasPendingChanges = hasPendingChanges

            // We also remove the errors if an field that can be erroneous has been changed
            if (sourceId && sourceId === this.titleInputId) {
                this.isTitleEmpty = false
            }

            if (sourceId && sourceId === this.descriptionDivId) {
                this.isDescriptionEmpty = false
            }
        },

        getDescErrorMsg (): string | null {
            return (this.isDescriptionEmpty) ? "Description cannot be empty" : (this.isDescriptionTooBig) ? "Description cannot exceed " + this.descMaxLength + " characters" : null
        },

        createNewFeedback () {
            const parsedMsg = getLineBreaksParsedFreeText((document.getElementById(this.descriptionDivId) as HTMLDivElement).innerHTML).trim()
            // First check that mandatory fields are filled, if not, we don't do anything
            this.isTitleEmpty = (document.getElementById(this.titleInputId) as HTMLInputElement).value.trim().length === 0
            this.isDescriptionEmpty = (parsedMsg.length === 0)

            // Check validity: description field shouldn't be exceeded 1000 characters (descMaxLength)
            this.isDescriptionTooBig = (parsedMsg.length > this.descMaxLength)

            if (!this.isTitleEmpty && !this.isDescriptionEmpty && !this.isDescriptionTooBig) {
                store.commit("setNewFeedback", {
                    title: (document.getElementById(this.titleInputId) as HTMLInputElement).value.trim(),
                    content: parsedMsg,
                    author: (document.getElementById(this.authorInputId) as HTMLInputElement).value.trim(),
                    tags: (this.$refs.tagsComponent as typeof FeedbackTags).getTags(),
                })
                // we only return to the feedback list if we succeeded getting a new id for that feedback in the state
                // cf watchers
            }
        },

        captchaVerified () {
            this.$data.isUserHuman = true
        },

        onPaste (pasteEvent: ClipboardEvent) {
            pasteTextOnly(pasteEvent)
        },
    },
})
